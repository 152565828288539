// Vendor
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Button, Popover, PopoverBody, Row } from "reactstrap";

// App
import { InvoiceStatusHelper } from "../../common/InvoiceStatusHelper";
import { GENERATE_INVOICES } from "../../common/Mutations";
import { ORDER, ORDER_GET_COMMENTS } from "../../common/Queries";
import { LayoutContext } from "../app/Layout";
import Loader from "../ui/Loader";
import orderReducer from "./orderReducer";
import { OrderComments } from "./subcomponents/OrderComments";
import { OrderCourseDetails } from "./subcomponents/OrderCourseDetails";
import { OrderEmails } from "./subcomponents/OrderEmails";
import { OrderGeneralInfo } from "./subcomponents/OrderGeneralInfo";

const defaultState = {
  raw: null,
  formatted: null,
  comments: [],
  refetchFlag: true,
  targetEmail: { email: "", active: false }
};
/** @type { OrderContext } */
export const OrderContext = React.createContext(defaultState);

function Order(props) {
  const id = props.match.params.id;
  const [state, dispatch] = useReducer(orderReducer, defaultState);
  const [isGenerateDisabled, setIsGenerateDisabled] = useState(false);
  const [popover, setPopover] = useState(false);
  const { addAlert } = useContext(LayoutContext);
  const togglePopover = () => setPopover(!popover);

  // Queries
  const [fetchOrder, { loading, error }] = useLazyQuery(ORDER, {
    onCompleted: ({ order }) => dispatch({ type: "RAW_DATA", payload: order }),
    variables: { id },
    fetchPolicy: "network-only" // Bug in pkg. onCompleted doesn't trigger without this
  });
  const { loading: commentsLoading, error: fetchCommentsError } = useQuery(
    ORDER_GET_COMMENTS,
    {
      onCompleted: ({ getOrderComments }) =>
        dispatch({ type: "COMMENTS_INIT", payload: getOrderComments }),
      onError: (err) => console.error(err),
      variables: { orderId: id }
    }
  );

  useEffect(() => {
    if (state.raw?.order) {
      formatOrder(state.raw.order);
    }
  }, [state.raw]);

  useEffect(() => {
    if (state.refetchFlag) {
      fetchOrder();
    }
  }, [state.refetchFlag, fetchOrder, id]);

  /**
   * Reformat [order] to group on [course] instead of [club]
   * @param { Object } order Order data from backend
   */
  function formatOrder(order) {
    let formattedOrder = { ...order };
    delete formattedOrder.clubs;
    formattedOrder.payNowTotal = 0;
    formattedOrder.stripeFee = 0;
    formattedOrder.courses = [];
    let courses = {};

    for (let club of order.clubs) {
      for (let tt of club.teetimes) {
        if (!courses[tt.courseId]) {
          courses[tt.courseId] = {
            _id: tt.courseId,
            clubBookingId: club._id,
            name: tt.courseName,
            slug: tt.slug,
            localTimeZone: club.localTimeZone,
            teeSheetProvider: club.teeSheetProvider,
            teeSheetClubId: club.teeSheetClubId,
            teeSheetCourseId: tt.teeSheetCourseId,
            discountAmount: tt.discountAmount,
            currency: tt.currency,
            total: 0,
            payNowTotal: 0,
            stripeFee: 0,
            vatRate: club.vatRate,
            teetimes: []
          };
        }

        courses[tt.courseId].teetimes.push(tt);
        if (!tt.bookingErrors.length) {
          formattedOrder.stripeFee += tt.fee ?? 0;
          courses[tt.courseId].stripeFee += tt.fee ?? 0;
          courses[tt.courseId].total += tt.price;
          if (!tt.payOnSite) {
            courses[tt.courseId].payNowTotal += tt.paidInAdvance;
            formattedOrder.payNowTotal += tt.paidInAdvance;
          }
        }
      }
    }
    for (let course of Object.keys(courses)) {
      formattedOrder.courses.push(courses[course]);
    }
    formattedOrder.currency = formattedOrder.courses[0].currency;
    dispatch({ type: "FORMATTED_DATA", payload: formattedOrder });
  }

  const handleGenerateInvoices = () => {
    setPopover(false);
    setIsGenerateDisabled(true);
    generateInvoicesForOrder({
      variables: { id }
    });
  };

  const onGenerateInvoicesForOrder = (res) => {
    if (res.generateInvoicesForOrder.ok) {
      return addAlert({
        color: "success",
        message: res.generateInvoicesForOrder.returnMessage
      });
    }
  };

  // mutations
  const [generateInvoicesForOrder] = useMutation(GENERATE_INVOICES, {
    onCompleted: onGenerateInvoicesForOrder,
    onError: () => {
      addAlert({
        color: "danger",
        message: "Error while trying to generate invoices"
      });
    }
  });

  const openOrderInvoices = () => {
    const baseUrl = window.location.origin;
    const url = `${baseUrl}/invoices?orderReference=${state.raw.order.orderReference}`;
    window.open(url, "_blank");
  };

  if (error)
    return (
      <p className="text-error">Failed to fetch order{`\n${error.message}`}</p>
    );

  if (loading || !state.formatted) return <Loader fullscreen />;

  return (
    <div className="page">
      <OrderContext.Provider value={{ state, dispatch }}>
        <div className="d-flex justify-content-between w-100 mb-2">
          <h2 className="mb-2">General info</h2>
          <div className="d-flex gap-2 align-items-center">
            <Button
              id="btn-view-invoices"
              className="ml-2"
              onClick={openOrderInvoices}
              color="link"
            >
              View Invoices
            </Button>
            <Button
              disabled={isGenerateDisabled}
              id="regenerate-invoices"
              onClick={togglePopover}
            >
              Generate Invoices
            </Button>
          </div>
        </div>
        <OrderGeneralInfo />

        <h2 className="mt-3 mb-0">Re-send order emails</h2>
        <OrderEmails />

        <h2 className="mt-3 mb-0">Courses</h2>
        <Row>
          {state.formatted?.courses?.map((course, index) => (
            <OrderCourseDetails course={course} key={index} />
          ))}
        </Row>
        {!commentsLoading && !fetchCommentsError && (
          <OrderComments comments={state.comments} />
        )}
        <Popover isOpen={popover} placement="left" target="regenerate-invoices">
          <PopoverBody>
            <div className="mb-2">
              <div>
                <b>Are you sure?</b>
              </div>
              <p className="mb-1">
                This will generate missing invoices for this order.
              </p>
              Status on newly created invoices will be:{" "}
              <span
                className="text"
                style={{
                  color: InvoiceStatusHelper.getStatusColor("pending"),
                  fontWeight: "bold"
                }}
              >
                pending
              </span>
            </div>
            <Button color="primary" size="sm" onClick={handleGenerateInvoices}>
              Yes
            </Button>
            <Button
              className="ml-2"
              color="danger"
              size="sm"
              onClick={togglePopover}
            >
              Cancel
            </Button>
          </PopoverBody>
        </Popover>
      </OrderContext.Provider>
    </div>
  );
}

export default Order;
